import React from "react";
import AboutXurealStyles from "./AboutXurealStyles";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "styled-components";

// Assets
import About from "../../assets/graphics/aboutgraphic.png";
import Xureal from "../../assets/images/branding/xureal-logo.png";

const AboutXureal = () => {
  const theme: any = useTheme();
  const isMediumScreen = useMediaQuery(`(max-width: 1144px)`);
  return (
    <AboutXurealStyles.Container>
      <AboutXurealStyles.Content>
      {isMediumScreen && (
            <>
              <span>
                <h1>About</h1>
                <img src={Xureal} alt="" />
              </span>
            </>
          )}
        <AboutXurealStyles.ImgContainer>
          <img
            src={About}
            alt=""
            style={{
              maxWidth: "750px",
              maxHeight: "650px",
            }}
          />
        </AboutXurealStyles.ImgContainer>
        <AboutXurealStyles.CopyContainer>
          {!isMediumScreen && (
            <>
              <h3>Our Story</h3>
              <span>
                <h1>About</h1>
                <img src={Xureal} alt="" />
              </span>
            </>
          )}
          <p>
            Xureal, the creators of Infobox.ai, are reshaping the digital
            landscape. As pioneers in the Metaverse and masters of immersive
            training, they now offer an AI tool designed to simplify business
            data handling.
            <br />
            <br />
            Its intuitive interface streamlines information management while
            ensuring confidentiality—a testament to Xureal's mission to
            challenge norms and empower humanity through technology.
          </p>
        </AboutXurealStyles.CopyContainer>
      </AboutXurealStyles.Content>
    </AboutXurealStyles.Container>
  );
};

export default AboutXureal;
