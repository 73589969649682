import React, { createContext, useContext, useState, useEffect } from "react";
import { getAllMessages, initXurealAI, createChatBot } from "../actions";
import { AuthContext, MessageType } from "../types/AuthTypes";

const ClientCtx = createContext({});

declare var infoboxaiBETA: any;

const ClientProvider = ({ children }: { children: React.ReactNode }) => {
  const [authorized, setAuthorized] = useState(false);
  const [initialized, setInitialize] = useState(false);
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [currentUserID, setCurrentUserID] = useState("");
  const [assistantName, setAssistantName] = useState("");
  const [companyName, setCompanyName] = useState("Xureal");
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [isSignupOpen, setSignupOpen] = useState(false);
  const [isForgotOpen, setForgotOpen] = useState(false);

  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [waitlistEmail, setWaitlistEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [googleDriveConnected, setGoogleDriveConnected] = useState<
    boolean | undefined
  >();
  const [data, setData] = useState<{ header: string; section: string }[]>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  const initializeApp = () => {
    const initAI = initXurealAI();

    Promise.all([initAI])
      .then((res) => {
        console.log(res[0]);
        if (res[0] === "SUCCESS") {
          setInitialize(true);
          console.log(
            "%c Xureal AI initialized",
            "font-size:16px;color:aquamarine"
          );
        }
      })
      .catch((err: any) => console.log("error", err));
  };

  // Check to see if tokens exist, if so set authorized to true
  useEffect(() => {
    if (
      localStorage.getItem("JWT_TOKEN") &&
      localStorage.getItem("REFRESH_TOKEN")
    ) {
      console.log("authorized");
      setAuthorized(true);
    }
  }, []);

  // intialize the application and get the configuration data
  useEffect(() => {
    if (initialized) return;
    // const initApi = initApiClient();
    initializeApp();
  }, [initialized, authorized]);

  const createBot = async (
    sources: string[],
    customization: {
      name: string;
      avatar: string;
      logo: string;
      color: string;
    },
    setLoadingModal: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setLoading(true);
    createChatBot({
      sources: sources,
      assistant_name: customization.name,
      widget_avatar: customization.avatar,
      widget_color: customization.color,
      widget_logo: customization.logo,
    }).then((response: any) => {
      if (response.result === "SUCCESS") {
        setLoadingModal(false);
        window.location.href = `${window.location.origin}/data`;
      }
    });

    // let tempID = generateTempID();
    // localStorage.setItem("tempID", tempID);
    // socket.emit("join-room", { tempID });
    // setIsScraping(true);
    // socket.emit("scrape", {
    //   urls: selectedUrls,
    //   companyName: companyName,
    //   tempID: tempID,
    // });
    // console.log('click')
    // scrapePage({
    //   urls: selectedUrls,
    //   companyName: companyName,
    //   tempID: tempID,
    // }).then((res: { result: string; data: ScrapedDataType }) => {
    //     console.log('scrape res', res)
    //   if (res.result === "SUCCESS") {
    //     setData(res.data);
    //     setTimeout(() => {
    //       setIsScraping(false);
    //       setLoading(false);
    //       setCurrentStep(3);
    //     }, 2000);
    //   }
    // });
  };

  useEffect(() => {
    let id = new URLSearchParams(window.location.search).get("id") as string;
    setCurrentUserID(id);
    if (initialized && id) {
      getAllMessages({ xurealID: id }).then(
        (res: { result: string; data: MessageType[] }) => {
          if (res.result === "SUCCESS") setMessages(res.data);
        }
      );
    }
    if (initialized) {
      let client_name = new URLSearchParams(window.location.search).get(
        "client"
      ) as string;
    }
  }, [initialized]);
  


  const handleBetaSignUp = () => {
    if (
      !waitlistEmail.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$")
    ) {
      setErrorMessage("Please enter a valid email");
      return;
    }
    const dataObj = {
      firstname: waitlistEmail.split("@")[0],
      lastname: waitlistEmail.split("@")[1],
      email: waitlistEmail,
      whitelisted: false,
      service: "infoboxai",
    };
    console.log("data obj", dataObj);
    setConfirmOpen(true);
    
   infoboxaiBETA.postSubscriberData(dataObj).then((res: any) => {
      console.log("beta res", res);
      setWaitlistEmail("");
    });
  };


  return (
    <ClientCtx.Provider
      value={{
        initialized,
        messages,
        setMessages,
        currentUserID,
        loading,
        setLoading,
        assistantName,
        data,
        setData,
        companyName,
        setCompanyName,
        createBot,
        isLoginOpen,
        setLoginOpen,
        isSignupOpen,
        setSignupOpen,
        isForgotOpen,
        setForgotOpen,
        authorized,
        setAuthorized,
        googleDriveConnected,
        setGoogleDriveConnected,
        handleBetaSignUp,
        infoboxaiBETA
      }}
    >
      {children}
    </ClientCtx.Provider>
  );
};

export const useClient = () => {
  return useContext(ClientCtx) as AuthContext;
};

export default ClientProvider;
